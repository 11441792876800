import { createWebHistory, createRouter } from "vue-router";
import Login from '../components/login/Login.vue'
import About from '../components/info/About.vue'
import Privacy from '../components/info/Privacy.vue'
import Dashboard from '../components/dashboard/Dashboard.vue'
import Leaderboards from '../components/leaderboards/Leaderboards.vue'
import Directions from '../components/directions/Directions.vue'
import Gamescreen from '../components/gamescreen/Gamescreen.vue'
import Gamesummary from '../components/gamescreen/Gamesummary.vue'
import Errorscreen from '../components/errors/Errorscreen.vue'
import HomeScreen from '../components/HomeScreen.vue'

const routes = [
    {
        path: '/',
        name: 'HomeScreen',
        component: HomeScreen,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/leaderboards',
        name: 'Leaderboards',
        component: Leaderboards
    },
    {   path: '/gamescreen',
        name: 'Gamescreen',
        component: Gamescreen
    },
    { 
        path: '/about',
        name: 'About',
        component: About
    },
    { 
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    {   
        path: '/directions', 
        name: 'Directions',
        component: Directions
    },
    { 
        path: '/summary', 
        name: 'Gamesummary',
        component: Gamesummary
    },
    {   
        path: '/error',
        name: 'Errorscreen',
        component: Errorscreen
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;