import { createStore } from "vuex";

export default createStore({
    state: {
        user: {
            user_id: -1,
            login_cookie: '',
            user_authentication: 'None',
            user_full_name: 'None',
            user_profile_pic: '',
            user_score: 0,
            user_experience: 0,
            user_skill: 0,
            user_skill_significant_decimal: 0,
            user_location: '',
            seen_tutorial_first_time: false
        },
        userNewsInteraction: {
            pointsReceived: 0,
            totalPointsPossible: 0,
            articlesPlayed: []
        },
        error: {
            action: '',
            message: '',
            details: '',
            timestamp: ''
        },
        reasonToTutorial: ''
    },
   
    mutations: {
        setUserNewsInteraction(state, userNewsInteraction) {
            state.userNewsInteraction = userNewsInteraction;
        },
        setTutorialReason(state, reason) {
            state.reasonToTutorial = reason;
        },
        setError(state, error) {
            state.error = error;
        }
    }
});
