<template>
    <div id="summaryScreen">
        <div id="summaryTitle">Game Summary</div>
        <div id="beckonToSignUp" v-if="user.user_authentication == 'anonymous'">
            <h3>Log in to keep track of score, compare your skill to other players, and play more recent news articles</h3>
        </div>
        <div v-if="userNewsInteraction.articlesPlayed.length > 0" id="summarySection">
            <div>
                {{responseText}} You got <b>{{Math.floor(userNewsInteraction.pointsReceived)}}</b> out of <b>{{userNewsInteraction.totalPointsPossible}}</b> points.
            </div>
            <div id="socialMediaSharing">
                <!-- Twitter Share via https://dev.twitter.com/web/tweet-button-->
                <a class="twitter-share-button"
                    :href="sharedTweet"
                    data-size="small">
                    Tweet
                </a>
                <button id="facebookShareButton" @click="shareOnFacebook">
                    <i class="fa fa-facebook-square mr-2"></i>Share
                </button>

                <!-- <iframe src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Ffakey.osome.iu.edu%2F&layout=button_count&size=small&appId=486756218366854&width=83&height=20" width="83" height="20" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> -->
            </div>
            See the detailed results below
            <br>
            <br>
            <div id="newsSummaryContentWrapperId">
                <div v-for="article, ix in userNewsInteraction.articlesPlayed" id="newsSummaryContent" :key="ix">
                    <hr id="dividerLine">
                    <div id="articleSummaryEntity">
                        <div id="newsSummaryObject" class="newsFeedFont">
                            <div id="newsSummaryImageContainer" class="imageShown">
                                <img id ="newsSummaryImage" v-bind:src="article.urltoimage">
                            </div>
                            <div class="newsArticle" id="newsSummaryTitle">{{ article.headline }}</div>
                            <div class="newsArticle" id="newsSummaryDescription">{{ article.description }}</div>
                        </div>
                        <div id="numSharesSnippetSummary" class="newsFeedFont">
                            <span>{{ article.timesShared.toLocaleString() }}</span> people liked or shared this
                        </div>
                        <div id="resultContent">
                            <div id="resultLogo" v-bind:class="{'green': article.gotRight, 'red': !article.gotRight}">
                                <template v-if="article.gotRight"><i class="fa fa-check" aria-hidden="true"></i></template>
                                <template v-else><i class="fa fa-times" aria-hidden="true"></i></template>
                            </div>
                        </div>
                        <div id="actionSummaryResponse">
                            {{ article.actionTaken }}. This <a id="linkToArticleSummary" target="_blank" :href="article.articleUrl">article</a> comes from {{ article.articleSource }}, a {{ article.articleTags }} source.
                        </div>
                    <br>
                    <br>
                    </div>
                </div>
            </div>
            <br>
        </div>
        <div id="backToDashboard"><button :class="{'facebookButton': user.user_authentication=='facebook', 'twitterButton': user.user_authentication!='facebook'}" 
            @click="backToDashboard()" type="button">Back to Dashboard</button>
        </div>
        <div id="playAgain"><button :class="{'facebookButton': user.user_authentication=='facebook', 'twitterButton': user.user_authentication!='facebook'}" 
            @click="playAgain()" type="button">Play Again!</button>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'Gamesummary',
        computed: {
            ...mapState(['user']),
            ...mapState(['userNewsInteraction']),
            sharedTweet() {
                return (
                    'https://twitter.com/intent/tweet?text=' +
                    encodeURIComponent(
                        'I played Fakey and got ' +
                        String(this.round(this.user.user_skill, 0)) +
                        '% skill in recognizing news from credible sources!'
                    )
                );
                // return 'https://twitter.com/intent/tweet?text=I%20played%20Fakey%20and%20got%20' + String(this.round(this.user.user_skill, 0)) + '%25%20skill%20in%20recognizing%20news%20from%20credible%20sources!'
            },
            shareOnFacebook() {
                const userSkill = String(this.round(this.user.user_skill, 0));

                const customMessage = `I played Fakey and got ${userSkill}% skill in recognizing news from credible sources!`;
                const shareUrl = 'https://fakey.osome.iu.edu/';

                FB.ui(
                    {
                        method: 'share',
                        href: shareUrl,
                        quote: customMessage,
                    },
                    function (response) {
                        if (response && !response.error_message) {
                            console.log('Post was shared successfully.');
                        } else {
                            console.error('Error while sharing:', response.error_message);
                        }
                    }
                );
            },
            responseText() {
                let accuracyRatio = Math.floor(this.userNewsInteraction.pointsReceived) / this.userNewsInteraction.totalPointsPossible
                if (accuracyRatio <= 0.3) {
                    return 'Better luck next time.'
                }
                else if (accuracyRatio < 0.7) {
                    return 'Not bad.'
                }
                else if (accuracyRatio < 1) {
                    return 'Good job!'
                }
                else if (accuracyRatio === 1) {
                    return 'Perfection!'
                }
            }
        },
        methods: {
            round (number, precision) {
                return Number(Math.round(number + 'e' + precision) + 'e-' + precision)
            },
            // removeTwitterShareObj() {
            //     // Currently need to delete the Twitter Share widget package every time, as it breaks styling
            //     // This solution is not optimal, but leaving it as is until we find something better
            //     var twitterSharePkg = document.getElementById('twitter-wjs')
            //     twitterSharePkg.parentNode.removeChild(twitterSharePkg)
            // },
            backToDashboard() {
                // this.removeTwitterShareObj()
                this.$router.push('/dashboard')
            },
            playAgain() {
                // this.removeTwitterShareObj()
                this.userNewsInteraction.totalPointsPossible = 0;
                this.userNewsInteraction.pointsReceived = 0;
                this.$store.commit('setUserNewsInteraction', this.userNewsInteraction)
                this.$router.push('/gamescreen')
            },
            backToLogin() {
                // this.removeTwitterShareObj()
                this.$router.push('/')
            }
        },
        mounted() {
            // Asynchronously loading share widget package (upon every mount)
            (function getTwitter (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0],
                t = window.twttr || {}
                if (d.getElementById(id)) return t
                js = d.createElement(s)
                js.id = id
                js.src = 'https://platform.twitter.com/widgets.js'
                fjs.parentNode.insertBefore(js, fjs)
                t._e = []
                t.ready = function (f) {
                t._e.push(f)
                }
                return t
            }(document, 'script', 'twitter-wjs'))
        }
}
</script>

<style scoped>

img {
    max-width: 100%;
    max-height: 100%;
}

#socialMediaSharing {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#facebookShareButton {
    width: 83px;
    height: 20px;
    background-color: #316ff6;
    color: white;
    border: none;
    font-size: 11px;
    font-family: Helvetica, Arial, sans-serif;
    white-space: nowrap;
    border-radius: 3px;
    padding: 3px 6px 0px 6px;
}

.facebookButton {
    width: auto;
    height: 50px;
    margin: 3px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #4267B2;
    color: white;
    font-size: 20px;
    font-style: bold;
}

.facebookButton:hover {
    width: auto;
    height: 50px;
    margin: 3px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #334f88;
    color: white;
    font-size: 20px;
    font-style: bold;
    cursor: pointer;
}

.green {
    color: green;
}

.red {
    color: red;
}

.twitterButton {
    width: auto;
    height: 50px;
    margin: 3px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #1DA1F2;
    color: white;
    font-size: 20px;
    font-style: bold;
}

.twitterButton:hover {
    width: auto;
    height: 50px;
    margin: 3px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #1a88cc;
    color: white;
    font-size: 20px;
    font-style: bold;
    cursor: pointer;
}

.newsFeedFont {
    font-family: 'Helvetica Neue', Arial, Sans-Serif;
}

.newsArticle {
    text-align: left;
}

#dividerLine {
    border-color: #6A6969;
    color: #6A6969;
    background-color: #6A6969;
    height: 1px;
    border: none;
}

#newsSummaryImageContainer {
    text-align: left;
}

#newsSummaryTitle {
    font-weight: bold;
    margin-bottom: 1%;
}

#newsSummaryContentWrapperId {
    background-color: white;
    border-color: #6A6969;
    border-width: thick;
    border-style: double;
    height: 600px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

#summaryScreen {
    text-align: center;
}

#summaryTitle {
    text-align: center;
    color: #6A6969;
    font-size: 140%;
    margin-bottom: 5px;
}

#numSharesSnippetSummary {
    color: #6A6969;
    margin-bottom: 5px;
}

#numSharesSnippetSummary span {
    font-weight: bold;
    color: #474747;
}

#newsSummaryObject {
    border: 1px solid #D3D3D3;
    padding: 0px;
    margin: 5px;
}

#beckonToSignUp {
    text-align: center;
}

#resultLogo {
    display: inline-block;
}

#resultText {
    display: inline-block;
    font-weight: bold;
}

#backToDashboard {
    margin-top: 3%;
}

#socialMediaSharing {
    margin: 2%;
}

</style>
