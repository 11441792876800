export default {
    data() {
      return {
        fbSdkReady: false,
      };
    },
    created() {
      this.initFacebookSDK();
    },
    methods: {
      initFacebookSDK() {
        if (typeof window.FB !== 'undefined') {
          this.fbSdkReady = true;
          return;
        }
  
        window.fbAsyncInit = () => {
          FB.init({
            appId: '1436246470450129', // Replace with your actual Facebook App ID
            cookie: true,
            xfbml: true,
            version: 'v15.0', // Replace with the version you are using
          });
  
          this.fbSdkReady = true;
        };
  
        if (!document.getElementById('facebook-jssdk')) {
          let script = document.createElement('script');
          script.id = 'facebook-jssdk';
          script.src = 'https://connect.facebook.net/en_US/sdk.js';
          document.head.appendChild(script);
        }
      },
      checkFbSdkReady(callback) {
        if (this.fbSdkReady) {
          callback();
        } else {
          setTimeout(() => {
            this.checkFbSdkReady(callback);
          }, 100);
        }
      }
    },
  };
  