<template>
    <div id="fake-news-game-app">
        <PWABanner />
        <router-view></router-view>
        <Footer />
        <!-- <div class="footer">
            <a href="#" @click.stop.prevent="goToPrivacy()">Privacy Policy</a>
        </div> -->
    </div>
</template>

<script>
/*
 * Root component
 */
import PWABanner from './components/PWABanner.vue'
import Footer from './components/footer/Footer.vue';
import { mapState } from 'vuex'

export default {
    name: 'App',
    components: {
        PWABanner,
        Footer
    },
    computed: {
        ...mapState(['user']),
    },
    methods: {
        loadUserFromLocalStorage() {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                this.user = JSON.parse(storedUser);
                return true;
            }
            return false;
        },
    },
    mounted() {
        let userLoggedIn = this.loadUserFromLocalStorage();
        console.log("this.user", this.user)
        if (userLoggedIn) {
            this.$router.push('/login');
        } else if (!this.user.seen_tutorial_first_time) {
            // If not logged in, show the tutorial
            this.user.seen_tutorial_first_time = true;
            this.$store.commit('setTutorialReason', 'newUser');
        }
    }
};
</script>


<style>
body {
    background-color: #E9EBEE;
}

html {
    scroll-behavior: smooth;
}

a {
    color: #0364CC;
}

a:hover {
    color: #0253AA;
}

@media only screen and (min-width: 500px) {
    body {
        width: 500px;
        text-align: center;
        margin: auto;
    }
}
</style>
