<template>
    <div id="topLevelGameContainer">
        <div v-show="articlesLoaded" id="playGameScreen">
            <div :class="{'facebookStyle': user.user_authentication=='facebook', 'twitterStyle': user.user_authentication!='facebook'}" id="playGameTopNav">
                <div id="backToDashDiv" 
                    v-if="user.user_authentication!='anonymous'" 
                    class='backToDash'>
                    <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                        @click="backToDashboard()" id="dashboardButton" type="button">
                        <fa icon="arrow-left"/>
                    </button>
                </div>
                <div id="playerStats">
                    <div id='pointsDiv'>
                        <div id="userPoints" class='gameScore' v-bind="user">
                            <b>Points:</b> {{ round(user.user_score, 0) }}
                        </div>
                        <div id='userPointsArrows' class='resultArrows'>
                            <span v-if="pointsUp" id="pointsArrowUp" aria-hidden="true"><fa icon="caret-up"/></span>
                        </div>
                    </div>
                    <div id='skillDiv' class="mb-2">
                        <div id="userSkill" class='gameSkill' v-bind="user" ><b>Skill:</b> {{ round(user.user_skill, user.user_skill_significant_decimal) }}%</div>
                        <div id='userSkillArrows' class='resultArrows'>
                            <span v-if="skillUp" id="skillArrowUp" aria-hidden="true"><fa icon="caret-up"/></span>
                            <span v-if="skillDown" id="skillArrowDown" aria-hidden="true"><fa icon="caret-down"/></span>
                        </div>
                    </div>
                </div>
                <div v-if="user.user_authentication!='anonymous'" class='profPic' v-bind="user" id="userProfilePicDiv">
                    <img class='profPicContainer' id ="userProfilePic" :src="user.user_profile_pic">
                </div>
            </div>
            <div id='newsContentWrapperId'>
                <div v-for="article, ix in articles" id="newsContent" :key="article.id">
                    <div id="articleEntity">
                        <div id="newsObject">
                            <div id="newsImageContainer" class="imageShown">
                                <img id="newsImage" :src="article.urltoimage">
                            </div>
                            <div id="newsTitleDescription" class="newsFeedFont">
                                <div class="newsArticle" id="newsTitle" v-html="article.headline"></div>
                                <div class="newsArticle" id="newsDescription" v-html="article.description"></div>
                            </div>
                        </div>
                        <div id="numSharesSnippet" class="newsFeedFont">
                            <span>{{ article.times_shared.toLocaleString() }}</span> people liked or shared this
                        </div>
                        <div id="newsContentControls">
                            <button :class="{'transparentButtons': (hintShown && !hintsShown[ix]) || (resultsShown[ix])}" class="playButton btn btn-outline-secondary" :disabled="(hintShown && !hintsShown[ix]) || (resultsShown[ix])" @click="shareArticle(ix)" id="shareArticleButton" type="button"><template><span v-if="user.user_authentication=='facebook'"><fa icon="share"/></span><span v-else><fa icon="retweet"/></span></template> <span class="newsFeedFont">Share</span></button>
                            <button :class="{'transparentButtons':(hintShown && !hintsShown[ix]) || (resultsShown[ix])}" class="playButton btn btn-outline-secondary" :disabled="(hintShown && !hintsShown[ix]) || (resultsShown[ix])" @click="likeArticle(ix)" id="likeArticleButton" type="button"><span v-if="user.user_authentication=='facebook'"><fa icon="thumbs-up"/></span><span v-else><fa :icon="['far', 'heart']"/></span> <span class="newsFeedFont">Like</span></button>
                            <button :class="{'transparentButtons': (hintShown && !hintsShown[ix]) || (resultsShown[ix])}" class="playButton btn btn-outline-secondary" :disabled="(hintShown && !hintsShown[ix]) || (resultsShown[ix])" @click="reportArticle(ix)" id="reportArticleButton" type="button"><span aria-hidden="true"><fa icon="exclamation-triangle"/></span>  <span class="newsFeedFont">Fact-Check</span></button>
                            <button :class="{'transparentButtons': (hintShown) || (resultsShown[ix])}" class="playButton btn btn-outline-secondary" :disabled="(hintShown) || (resultsShown[ix])" @click="showHintArticle(ix)" id="showHintArticleButton" type="button"><span aria-hidden="true"><fa :icon="['far', 'lightbulb']"/></span>  Hint</button>
                            <button :class="{'transparentButtons': (hintShown) || (resultsShown[ix])}" class="playButton btn btn-outline-secondary" :disabled="(hintShown) || (resultsShown[ix])" @click="skipArticle(ix)" id="skipArticleButton" type="button"><span aria-hidden="true"><fa icon="angle-double-right"/></span> Skip</button>
                        </div>
                    </div>
                <div>
                <transition name="slide">
                    <div v-show="resultsShown[ix] || hintsShown[ix]" id="actionResponse">
                        <div v-if="resultsShown[ix]">
                            This <a id="linkToArticle" target="_blank" :href="allResults[ix].url">article</a> comes from {{ allResults[ix].news_source }}, a {{ allResults[ix].tags }} source.
                            <br>
                            <button @click="removeArticle(ix)" class="playButton btn btn-outline-secondary">Hide</button>
                        </div>
                        <div v-if="hintsShown[ix] && !resultsShown[ix]">
                            This <a id="linkToArticle" target="_blank" :href="allResults[ix].url">article</a> comes from {{ allResults[ix].news_source }}.
                        </div>
                    </div>
                </transition>
            </div>
            <br>
            <hr id="dividerLine">
            </div>
            <button :class="{'facebookStyle': user.user_authentication=='facebook', 'twitterStyle': user.user_authentication!='facebook'}" @click="toSummary()" id="toSummary" class="btn btn-twitter mb-2" type="button">Done</button>
            </div>
        </div>
        <div id="loadingScreen" v-if="!articlesLoaded">
        Loading...
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import { mapState } from 'vuex'; 

export default {
    name: 'Gamescreen',
    data() {
        return {
            articles: [],
            totalArticles: 0,
            articlesPlayed: 0,
            articlesLoaded: false,
            allResults: [],
            articleResults: {
                news_source: '',
                tags: '',
                url: '',
                times_shared: 0,
                actionTaken: '',
                gotRight: false
            },
            pointsUp: false,
            skillUp: false,
            skillDown: false,
            error: {
                action: '',
                message: '',
                details: '',
                timestamp: ''
            },
            articlePlayed: -1,
            hintShown: false,
            hintsShown: [],
            resultsShown: [],
            isImageBig: []
        }
    },
    computed: {
        ...mapState(['user']),
        ...mapState(['userNewsInteraction'])
    },
    methods: {
        round(number, precision) {
            return Number(Math.round(number + 'e' + precision) + 'e-' + precision)
        },
        getTimestamp() {
            // Creating proper agnostic time to show to the user
            let currentdate = new Date()
            let timezoneOffset = currentdate.getTimezoneOffset() / 60
            let timezoneSymbol = '+'

            if (timezoneOffset > 0) {
                timezoneSymbol = '-'
            }
            let dateTimeStamp = (currentdate.getMonth() + 1) + '/' +
                currentdate.getDate() + '/' +
                currentdate.getFullYear() + ' at ' +
                currentdate.getHours() + ':' +
                currentdate.getMinutes() + ':' +
                currentdate.getSeconds() + ' UTC' + timezoneSymbol +
                (currentdate.getTimezoneOffset() / 60) +
                ':00'
            return dateTimeStamp
        },
        //  NOT EXPLICITLY DONE YET BECAUSE IT IS IMPLICITLY DONE BY INLINE ALIGNMENT
        // checkImageSizes () {
        //   let img = document.getElementById('newsImage')
        //   let width = img.clientWidth
        //   let height = img.clientHeight
        //   console.log('width')
        //   console.log(width)
        //   console.log('height')
        //   console.log(height)
        // },
        backToDashboard() {
            this.$router.push('/dashboard')
        },
        disableAnimationForNewOne() {
        // Updating the animation to remove up/down green arrows for the next time user interacts with news
            this.pointsUp = false
            this.skillUp = false
            this.skillDown = false
            this.hintShown = false
        },
        async getArticles() {
            let vm = this
            try {
                let response = await axios({
                method: "POST",
                url: `${this.$databaseConfig}/getnewsbatch/`,
                data: {user_id: this.user.user_id}
            });
            vm.articles = response.data.news_content

            Object.keys(vm.articles).forEach(function (articleKey) {
                // Initiating the results and hint shown arrays to all false, to not show any results or hints to the screen
                vm.resultsShown.push(false)
                // Retrieving total articles
                vm.hintsShown.push(false)
                vm.totalArticles++
                // Creating results template for the articles
                var articleResults = {
                    news_source: '',
                    tags: '',
                    url: '',
                    times_shared: vm.articles[articleKey].times_shared,
                    actionTaken: '',
                    gotRight: false
                }
                // Pushing empty results to the global results object
                vm.allResults.push(articleResults)
            })
            setTimeout(() => {
                // Removing the article from the article content list rendered after a few seconds unless user explicitly hides it
                vm.articlesLoaded = true
                }, 500)
            }
            catch(error) {
                // Logging error
                this.error.action = 'On retrieving some articles for the user.'
                this.error.message = error.status.toString()
                this.error.details = error.statusText
                this.error.timestamp = this.getTimestamp()
                this.$store.commit("setError", this.error)
                this.$router.push('/error')
            }
        },
        addToArticlesPlayedPool(articleIndex) {
            let articlePlayed = {
                urltoimage: this.articles[articleIndex].urltoimage,
                headline: this.articles[articleIndex].headline,
                description: this.articles[articleIndex].description,
                articleSource: this.allResults[articleIndex].news_source,
                articleUrl: this.allResults[articleIndex].url,
                articleTags: this.allResults[articleIndex].tags,
                gotRight: this.allResults[articleIndex].gotRight,
                timesShared: this.articles[articleIndex].times_shared,
                actionTaken: this.allResults[articleIndex].actionTaken
            }
            this.userNewsInteraction.articlesPlayed.push(articlePlayed)
        },
        removeArticle(articleIndex) {
            // Only if results are currently visible for the article
            if (this.resultsShown[articleIndex] === true) {
                // Delete article
                // this.$delete(this.articles, articleIndex)
                delete this.articles[articleIndex]
                // Increment articles played by player
                this.articlesPlayed++
                // Hide result
                this.resultsShown[articleIndex] = false

                // Check if the user has finished consuming all of the articles
                this.checkIfEndGame()
            }
        },
        updateUserInformationAndRetrieveArticleResults(ix, response, actionType) {
            // Played an article
            this.userNewsInteraction.totalPointsPossible += 10

            // Retrieving results for classifying the article this way to show the user
            this.allResults[ix].news_source = response.data.news_source
            this.allResults[ix].tags = response.data.tags
            this.allResults[ix].url = response.data.url

            // Finding out how many points player received in order to decide
            // How to offer feedback and whether they got it right or wrong
            // Note that the feedback is very tightly coupled with scoring rules
            // Which are:
            // Share mainstream news: 10 points, Share fake-news: 0 points
            // Like mainstream news: 8 points, Like fake-news: 2 points
            // Fact-Check mainstream news: 4 points, Fact-Check mainstream news 10 points
            // If player uses a hint, they get half the points they would normally get

            let responseUserScore = Math.round(response.data.user_score*10);
            let currentPointsReceived = this.round((responseUserScore - this.user.user_score), 0)
            this.userNewsInteraction.pointsReceived += currentPointsReceived

            // Checking if the user got the article response correctly and updating the response snippet accordingly
            if (actionType === 'report') {
                this.allResults[ix].actionTaken = 'You fact-checked it'
                if (currentPointsReceived === 10 || currentPointsReceived === 5) {
                // User reported fake news and got it right
                this.allResults[ix].gotRight = true
                // Used for got right/got wrong animation (green/red arrows)
                this.pointsUp = true
                }
                else {
                // User reported real news and got it wrong
                this.allResults[ix].gotRight = false
                }
            }
            else if (actionType === 'share') {
                this.allResults[ix].actionTaken = 'You shared it'
                if (currentPointsReceived === 10 || currentPointsReceived === 5) {
                // User shared real news and got it right
                this.allResults[ix].gotRight = true
                // Used for got right/got wrong animation (green/red arrows)
                this.pointsUp = true
                }
                else {
                // User shared fake news and got it wrong
                this.allResults[ix].gotRight = false
                }
            }
            else if (actionType === 'like') {
                this.allResults[ix].actionTaken = 'You liked it'
                if (currentPointsReceived === 8 || currentPointsReceived === 4) {
                // User liked real news
                this.allResults[ix].gotRight = true
                // Used for got right/got wrong animation (green/red arrows)
                this.pointsUp = true
                }
                else {
                // User liked fake news
                this.allResults[ix].gotRight = false
                // Used for got right/got wrong animation (green/red arrows)
                this.pointsUp = true
                }
            }

            // Used for got right/got wrong animation (green/red arrows)
            // If skill has improved
            if (this.user.user_skill < response.data.new_skill) {
                this.skillUp = true
            }
            // Skill decreased
            else if (this.user.user_skill > response.data.new_skill) {
                this.skillDown = true
            }

            // Updating user information
            this.user.user_skill = response.data.new_skill
            this.user.user_score = this.round(10 * response.data.user_score, 0)
            this.user.user_experience = this.round(response.data.new_experience, 0)
        },
        async reportArticle(articleIndex) {
            var hintUsed = (this.hintShown === true) ? 1 : 0
            this.disableAnimationForNewOne()
            // Record result and retrieve new user information
            let vm = this
            try {
                let response = await axios({
                    method: "POST",
                    url: `${this.$databaseConfig}/recordarticleresult/`,
                    data: {
                        login_cookie: vm.user.login_cookie,
                        news_id: vm.articles[articleIndex].newsid,
                        user_classification: 0,
                        hint_used: hintUsed,
                        user_response_time: 0,
                        times_shared: vm.allResults[articleIndex].times_shared
                    }
                })
                vm.updateUserInformationAndRetrieveArticleResults(articleIndex, response, 'report')

                // Updating the article played element to toggle the result outcome
                vm.articlePlayed = articleIndex

                // Adding to articles played pool to show up results in summary screen
                vm.addToArticlesPlayedPool(articleIndex)

                // Show response results to the user actionResponse
                vm.resultsShown[articleIndex] = true
            }
            catch(error) {
                // Logging error
                    this.error.action = 'On reporting/disputing an article.'
                    this.error.message = error.status.toString()
                    this.error.details = error.statusText
                    this.error.timestamp = this.getTimestamp()
                    this.$store.commit("setError", this.error)
                    this.$router.push('/error')
            }
        },
        async likeArticle(articleIndex) {
            var hintUsed = (this.hintShown === true) ? 1 : 0
            this.disableAnimationForNewOne()

            // Reflecting the share action to the screen
            this.articles[articleIndex].times_shared++

            // Record result and retrieve new user information
            let vm = this
            try {
                let response = await axios({
                    method: "POST",
                    url: `${this.$databaseConfig}/recordarticleresult/`,
                    data: {
                        login_cookie: vm.user.login_cookie,
                        news_id: vm.articles[articleIndex].newsid,
                        user_classification: 1,
                        hint_used: hintUsed,
                        user_response_time: 0,
                        times_shared: vm.allResults[articleIndex].times_shared
                    }
                });
                vm.updateUserInformationAndRetrieveArticleResults(articleIndex, response, 'like')

                // Updating the article played element to toggle the result outcome
                vm.articlePlayed = articleIndex

                // Adding to articles played pool to show up results in summary screen
                vm.addToArticlesPlayedPool(articleIndex)

                // Show response results to the user actionResponse
                vm.resultsShown[articleIndex] = true
            }
            catch(error) {
                // Logging error
                this.error.action = 'On liking/approving an article.'
                this.error.message = error.status.toString()
                this.error.details = error.statusText
                this.error.timestamp = this.getTimestamp()
                this.$store.commit("setError", this.error)
                this.$router.push('/error')
            }
        },
        async shareArticle(articleIndex) {
            var hintUsed = (this.hintShown === true) ? 1 : 0
            this.disableAnimationForNewOne()

            // Reflecting the share action to the screen
            this.articles[articleIndex].times_shared++

            // Record result and retrieve new user information
            let vm = this
            try {
                let response = await axios({
                    method: "POST",
                    url: `${this.$databaseConfig}/recordarticleresult/`,
                    data: {
                        login_cookie: vm.user.login_cookie,
                        news_id: vm.articles[articleIndex].newsid,
                        user_classification: 2,
                        hint_used: hintUsed,
                        user_response_time: 0,
                        times_shared: vm.allResults[articleIndex].times_shared
                    }
                });
                vm.updateUserInformationAndRetrieveArticleResults(articleIndex, response, 'share')

                // Updating the article played element to toggle the result outcome
                vm.articlePlayed = articleIndex

                // Adding to articles played pool to show up results in summary screen
                vm.addToArticlesPlayedPool(articleIndex)

                // Show response results to the user actionResponse
                vm.resultsShown[articleIndex] = true
            }
            catch(error) {
                // Logging error
                this.error.action = 'On retweeting/resharing an article.'
                this.error.message = error.status.toString()
                this.error.details = error.statusText
                this.error.timestamp = this.getTimestamp()
                this.$store.commit("setError", this.error)
                this.$router.push('/error')
            }
        },
        async showHintArticle(articleIndex) {
            this.disableAnimationForNewOne()
            // Record result and retrieve new user information
            let vm = this
            try {
                let response = await axios({
                    method: "POST",
                    url: `${this.$databaseConfig}/getarticlehint/`,
                    data: {
                        login_cookie: vm.user.login_cookie,
                        news_id: vm.articles[articleIndex].newsid
                    }
                });
                vm.allResults[articleIndex].news_source = response.data.news_source
                vm.allResults[articleIndex].url = response.data.url
                // Show hint to the user and disable certain buttons
                vm.hintsShown[articleIndex] = true
                vm.hintShown = true
                // Toggling transition given animation conflict (this is a bug so if this bug can be fixed, we can try removing this code below and seeing how to fix otherwise)
                vm.pointsUp = true
                vm.pointsUp = false
            }
            catch(error) {
                // Logging error
                vm.error.action = 'On retrieving a hint for an article.'
                vm.error.message = error.status.toString()
                vm.error.details = error.statusText
                vm.error.timestamp = vm.getTimestamp()
                this.$store.commit("setError", this.error)
                this.$router.push('/error')
            }
        },
        skipArticle(articleIndex) {
            this.disableAnimationForNewOne()
            // Delete article
            // this.$delete(this.articles, articleIndex)
            delete this.articles[articleIndex]
            // Check if the user has finished consuming all of the articles
            this.checkIfEndGame()
        },
        checkIfEndGame() {
            if (Object.keys(this.articles).length === 0) {
                this.toSummary()
            }
        },
        toSummary() {
            // Scroll to the top of the screen
            window.scrollTo(0, 0)
            // Submit final game scores to be reviewed later
            this.$store.commit("setUserNewsInteraction", this.userNewsInteraction)
            this.$router.push('/summary')
        },
        resizeNewsFeed() {
            // Dynamically retrieving the screen of the user's device in order to ensure that the
            // Top score div and bottom scrolling articles fit the whole screen
            let screenHeight = (window.innerHeight > 0) ? window.innerHeight : screen.height
            let topDivHeight = document.getElementById('playGameTopNav').style.height
            let scrollingHeight = screenHeight - topDivHeight - 70
            document.getElementById('newsContentWrapperId').style.height = String(scrollingHeight) + 'px'
        }
    },
    beforeMount() {
        // Initiating articles played to empty
        this.userNewsInteraction.pointsReceived = 0
        this.userNewsInteraction.totalPointsPossible = 0
        this.userNewsInteraction.articlesPlayed = []
        this.$store.commit("setUserNewsInteraction", this.userNewsInteraction)
    },
    mounted() {
        this.resizeNewsFeed()
        this.getArticles()
    }
}

// This method is very similar to resizeNewsFeed but is pertinent whenever user initiates screen resizing or rotation
window.addEventListener('resize', function () {
  // Dynamically retrieving the screen of the user's device in order to ensure that the
  // Top score div and bottom scrolling articles fit the whole screen
  try {
    // If this is called from any other page, it will likely fail here
    let topDivHeight = document.getElementById('playGameTopNav').style.height
    let screenHeight = (window.innerHeight > 0) ? window.innerHeight : screen.height
    let scrollingHeight = screenHeight - topDivHeight - 70
    document.getElementById('newsContentWrapperId').style.height = String(scrollingHeight) + 'px'
  }
  catch (err) {
    // Not handling error as this piece of code should only be successful on the game screen
  }
}, true)
</script>

<style scoped>

img {
    max-width: 100%;
    max-height: 100%;
}

.newsArticle {
    text-align: left;
}

.transparentButtons {
    opacity: 0.2;
}

.newsFeedFont {
    font-family: 'Helvetica Neue', Arial, Sans-Serif;
}

.facebookStyle {
    background-color: #4267B2;
    color: white;
}

/* .facebookStyle:hover {
    background-color: #334f88;
    color: white;
    cursor: pointer;
} */

.twitterStyle {
    background-color: #1DA1F2;
}

.btn-twitter:hover {
    background-color: #1a88cc;
}

/* .twitterStyle:hover {
    background-color: #1a88cc;
    cursor: pointer;
} */


.profPicContainer {
    height: 50px;
    width: 50px;
}

.profPicContainer img {
    max-height: 100%;
    max-width: 100%
}

[v-cloak] {
    display: none;
}

.facebookStyleButton {
    color: white;
    width: auto;
    height: 30px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #4267B2;
    font-size: 20px;
    font-style: bold;
}

.facebookStyleButton:hover {
    color: white;
    width: auto;
    height: 30px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #334f88;
    font-size: 20px;
    font-style: bold;
    cursor: pointer;
}

.twitterStyleButton {
    color: black;
    width: auto;
    height: 30px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #1DA1F2;
    font-size: 20px;
    font-style: bold;
}

.twitterStyleButton:hover {
    color: black;
    width: auto;
    height: 30px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #1a88cc;
    font-size: 20px;
    font-style: bold;
    cursor: pointer;
}

.gameScore {
    width: 100px;
}

.gameSkill {
    width: 100px;
}

.resultArrows {
    width: 20px;
}

#newsContentControls {
    display: flex;
    justify-content: center;
}

.playButton {
    padding: .4em;
    color: #505050;
    font-size: 15px;
}

.playButton:hover {
    background-color: #e4e2e2;
}

.slide-enter {
    opacity: 0;
}

.slide-enter-active {
    transition: opacity 1s;
}

#dividerLine {
    border-color: #6A6969;
    color: #6A6969;
    background-color: #6A6969;
    height: 1px;
    border: none;
}

#playGameScreen {
    text-align: center;
    background-color: white;
}

#newsObject {
    text-align: left;
    border: 1px solid #D3D3D3;
    padding: 0px;
    margin: 5px;
}

#loadingScreen {
    margin-top: 5%;
    color: #6A6969;
    font-size: 140%;
    text-align: center;
}

#numSharesSnippet {
    color: #6A6969;
    margin-bottom: 5px;
}

#numSharesSnippet span {
    font-weight: bold;
    color: #474747;
}

#toSummary {
        width: 40%;
        height: 70px;
        border: 2px solid;
        border-radius: 25px;
        color: white;
        font-size: 20px;
        font-style: bold;
}

#newsContentWrapperId {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

#playerStats {
    vertical-align: middle;
    display: inline-block;
    margin-right: 10%;
}

#backToDashDiv {
    vertical-align: middle;
    display: inline-block;
    margin-right: 10%;
}

#userProfilePicDiv {
    vertical-align: middle;
    display: inline-block;
    margin: 1%;
}

#userPoints, #userSkill {
    text-align: left;
    display: inline;
}

#userPointsArrows, #userSkillArrows {
    text-align: left;
    display: inline;
}

#pointsArrowUp, #skillArrowUp {
    color: #00FF00;
    font-size: 100%;
    animation: arrow-up 1s linear infinite;
}

#skillArrowDown {
    color: #FF0000;
    font-size: 100%;
    animation: arrow-down 1s linear infinite;
}

#pointsDiv {
    margin: 4px;
    text-align: left;
    width: 130px;
    height: 15px;
}

#skillDiv {
    margin: 4px;
    text-align: left;
    width: 130px;
    height: 15px;
}

#topLevelGameContainer {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-top: 0px;
    border-bottom: 0px;
}


#newsImageContainer {
    display: inline-block;
    text-align: left;
}

#newsTitleDescription {
    display: inline-block;
    vertical-align: top;
}

#newsTitle {
    font-weight: bold;
    margin-bottom: 1%;
}

#dashboardButton {
    font-style: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@keyframes arrow-up {
    0% { transform: translateY(0px) }
    50% { transform: translateY(2px) }
    100% { transform: translateY(-2px) }
}

@keyframes arrow-down {
    0% { transform: translateY(0px) }
    50% { transform: translateY(-2px) }
    100% { transform: translateY(2px) }
}

</style>
