import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CONFIG from "./statics/config/client-config.json"

library.add(fas, far)

const app = createApp(App);
app.config.globalProperties.$databaseConfig = CONFIG['DjangoServerUrl'];
app.config.globalProperties.$twitterConfig = CONFIG['TwitterOauth'];
app.config.globalProperties.$googleConfig = CONFIG['GoogleClientId'];

app.use(router).use(store)

app.component('fa', FontAwesomeIcon)

app.mount('#app')
