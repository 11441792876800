<template>
  <div>
    <div v-if="loadedData" id="leaderboardsScreen">
      <div id="backToDashboard">
        <button
          :class="{
            facebookStyleButton: user.user_authentication == 'facebook',
            twitterStyleButton: user.user_authentication != 'facebook',
          }"
          @click="backToDashboard()"
          id="dashboardButton"
          type="button"
          class="btn"
        >
          <fa icon="arrow-left"/>
        </button>
      </div>
      <div id="leaderboardsTitle">Top Players</div>
      <div id="leaderboardContent">
        <table id="leadearboardsTable">
            <tr
            :class="{
                facebookStyle: user.user_authentication == 'facebook',
                twitterStyle: user.user_authentication != 'facebook',
            }"
            >
            <th>Rank</th>
            <th>Name</th>
            <th>Points</th>
            <th>Skill</th>
            </tr>
            <tr v-for="(player, ix) in topPlayers" :key="ix">
            <td>{{ ix + 1 }}</td>
            <td>{{ player.fullname }}</td>
            <td class="scores">{{ round(10 * player.points, 0) }}</td>
            <td class="scores">
                {{
                round(player.skill, leaderboards_skill_significant_decimal) + "%"
                }}
            </td>
            </tr>
        </table>
      </div>
    </div>
    <div v-else id="loadingScreen">Loading...</div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
    name: 'Leaderboards',
    data() {
        return {
            topPlayers: [],
            loadedData: false,
            leaderboards_skill_significant_decimal: 0
        };
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        round(number, precision) {
            return Number(Math.round(number + "e" + precision) + "e-" + precision);
        },
        getSignificantDecimalSkill(player) {
            if (player.points > 10) {
                // Only relevant if score is greater than 10
                this.leaderboards_skill_significant_decimal = Math.floor(
                Math.log(player.points / 10) / Math.log(10)
                );
            }
        },
        getTimestamp() {
            // Creating proper agnostic time to show to the user
            let currentdate = new Date();
            let timezoneOffset = currentdate.getTimezoneOffset() / 60;
            let timezoneSymbol = "+";

            if (timezoneOffset > 0) {
                timezoneSymbol = "-";
            }
            let dateTimeStamp =
                currentdate.getMonth() +
                1 +
                "/" +
                currentdate.getDate() +
                "/" +
                currentdate.getFullYear() +
                " at " +
                currentdate.getHours() +
                ":" +
                currentdate.getMinutes() +
                ":" +
                currentdate.getSeconds() +
                " UTC" +
                timezoneSymbol +
                currentdate.getTimezoneOffset() / 60 +
                ":00";
            return dateTimeStamp;
        },
        async getScores() {
            let vm = this;
            try {
                let response = await axios({
                    method: "GET",
                    url: `${this.$databaseConfig}/getleaderboards/`
                });
                vm.topPlayers = response.data.top_ten_players;
                // Having the top player (which will have the skill with most decimal significance) represent sig for everyone
                this.getSignificantDecimalSkill(vm.topPlayers[0]);
                vm.loadedData = true;
            }
            catch(error) {
                // Logging error
                console.debug(error)
                this.error.action = "Upon retrieving leaderboards.";
                this.error.message = error.status.toString();
                this.error.details = error.statusText;
                this.error.timestamp = this.getTimestamp();
                this.$store.commit("setError", this.error)
                this.$router.push('/error')
            }
        },
        backToDashboard() {
            this.$router.push('/dashboard')
        },
    },
    mounted() {
        this.getScores();
    }
};
</script>

<style scoped>
#leaderboardsScreen {
  text-align: center;
}

#leaderboardsTitle {
  color: #6A6969;
  font-size: 140%;
  margin-bottom: 5px;
}

#leaderboardContent {
    margin-left: .5em;
    margin-right: .5em;
}

#backToDashboard {
  text-align: left;
}

#loadingScreen {
  margin-top: 5%;
  color: #6A6969;
  font-size: 140%;
  text-align: center;
}

.twitterButton {
  width: 30%;
  height: 35px;
  margin: 3%;
  border: 2px solid;
  border-radius: 25px;
  background-color: #1da1f2;
  font-weight: bold;
  color: white;
  font-size: 12px;
  font-style: bold;
}

/* Leaderboards Styling */
table {
  font-family: 'Helvetica Neue', arial, sans-serif;
  width: 100%;
  border-width: thin;
}

td,
th {
  border: 1px solid #1da1f2;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #c0c0c0;
}

tr {
  background-color: white;
}

.facebookStyle {
  background-color: #4267b2;
  color: white;
}

.twitterStyle {
  background-color: #1da1f2;
}

#dashboardButton {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.scores {
  text-align: right;
}

.facebookStyleButton {
  margin: 3%;
  color: white;
  width: 20%;
  height: 30px;
  border: 2px solid;
  border-color: #6A6969;
  border-radius: 25px;
  background-color: #4267b2;
  font-size: 20px;
  font-style: bold;
}

.facebookStyleButton:hover {
  margin: 3%;
  color: white;
  width: 20%;
  height: 30px;
  border: 2px solid;
  border-color: #6A6969;
  border-radius: 25px;
  background-color: #334f88;
  font-size: 20px;
  font-style: bold;
  cursor: pointer;
}

.twitterStyleButton {
  margin: 3%;
  width: 20%;
  height: 30px;
  border: 2px solid;
  border-radius: 25px;
  background-color: #1da1f2;
  font-size: 20px;
  font-style: bold;
}

.twitterStyleButton:hover {
  margin: 3%;
  width: 20%;
  height: 30px;
  border: 2px solid;
  border-radius: 25px;
  background-color: #1a88cc;
  font-size: 20px;
  font-style: bold;
  cursor: pointer;
}
</style>
