<template>
    <div id="directionsScreen">
        <div v-if="reasonToTutorial=='voluntary'" id="backToDashboard">
            <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                @click="backToDashboard()" class="dashboardButton" type="button" aria-label="back to dashboard">
                <fa icon="arrow-left"/>
            </button>
        </div>
        <div v-else-if="reasonToTutorial=='clickedFromFooter'" id="backOneScreen">
            <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                @click="goBack()" class="dashboardButton" type="button" aria-label="back one screen">
                <fa icon="arrow-left"/>
            </button>
        </div>
        <div v-else id="skipTutorial">
            <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                @click="skipTutorial()" type="button">Skip Tutorial
            </button>
        </div>
        <div id="directionsTitle">How to Play</div>
        <div id="directionsPages">
            <div v-if="tutorialPageVisible==1" class="tutorialSnippets">
                <br>
                This game aims to teach media literacy and study how people interact with misinformation.
                <br>
                <br>
                You will see a simulated news feed with articles like the one below. Some coming from legitimate news sources. And some from sites that typically publish false or misleading reports, clickbait headlines, conspiracy theories, junk science, and other types of misinformation.
                <br>
                <br>
                <div id="articleAndButtonsDirections">
                    <img src="../../statics/directions_images/TutorialSampleArticleAndButtons.png">
                </div>
                <br>
                Your goal is to support a healthy social media experience by promoting information from reliable sources and not from low credibility sources.
                <br>
                <div class="tutorialButtonsDiv">
                    <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                        @click="nextTutorialPage()">Next
                    </button>
                </div>
            </div>
            <div v-if="tutorialPageVisible==2" class="tutorialSnippets">
                <br>
                Inspect each article in the feed just as you would in your favorite
                social media. Look at the image, headline, and description to decide
                whether it's credible, in which case, you may <b>share</b>
                or <b>like</b> it. Select <b>fact-check</b> to indicate that you
                don't trust the article.
                <br>
                <br>
                <div id="gameButtonsDirections">
                    <img src="../../statics/directions_images/GameButtons.png">
                </div>
                <br>
                These actions are just simulations and will not affect your social
                media profile. Select <b>hint</b> or <b>skip</b> if you are not sure.
                <br>
                <br>
                <div class="tutorialButtonsDiv">
                    <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                        @click="previousTutorialPage()">Previous
                    </button>
                    <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                        @click="nextTutorialPage()">Next
                    </button>
                </div>
            </div>
            <div v-if="tutorialPageVisible==3" class="tutorialSnippets">
                The game will score each action you take. Your <b>points</b> describe how much experience you have. Your <b>skill</b> measures how good you are at promoting information from trustworthy sources and spotting articles from low credibility sources.
                <br>
                <br>
                You will get top points for sharing articles from credible sources and fact-checking articles from suspicious sources.
                <br>
                <br>
                <div id="rewardDirections">
                    <img src="../../statics/directions_images/TutorialReward.png">
                </div>
                <br>
                On the other hand, sharing an article from an untrusted source will cause your skill to decrease.
                <br>
                <br>
                <div id="penalizationDirections">
                    <img src="../../statics/directions_images/TutorialPenalization.png">
                </div>
                <br>
                Liking a suspicious article or fact-checking information from a legitimate source will get you a few points. You may also use a <b>hint</b>, but it will cost you a few points.
                <br>
                <div class="tutorialButtonsDiv">
                    <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                        @click="previousTutorialPage()">Previous
                    </button>
                    <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                        @click="nextTutorialPage()">Next
                    </button>
                </div>
            </div>
            <div v-if="tutorialPageVisible==4" class="tutorialSnippets">
                <br>
                <br>
                Scroll down to view more articles. Scrolling past an article or selecting <b>skip</b> will not affect your score.
                <br>
                <br>
                <div id="scrollArticlesDirections">
                    <img src="../../statics/directions_images/TutorialScrollArticles.png">
                </div>
                <br>
                To end the game session, scroll down to the bottom and press ‘done’ to see how you did. You may play as many sessions as you like. Check the leaderboard to see how your skills compare to other players!
                <br>
                <br>
                <div class="tutorialButtonsDiv">
                    <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                        @click="previousTutorialPage()">Previous
                    </button>
                    <template v-if="reasonToTutorial=='voluntary'">
                        <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                            @click="backToDashboard()">Done
                        </button>
                    </template>
                    <template v-else-if="reasonToTutorial=='clickedFromFooter'">
                        <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                            @click="goBack()">Done
                        </button>
                    </template>
                    <template v-else>
                        <button :class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" 
                            @click="skipTutorial()">Done
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Directions',
    data() {
        return {
            tutorialPageVisible: 1
        }
    },
    computed: {
        ...mapState(['user']),
        ...mapState(['reasonToTutorial'])
    },
    methods: {
        backToDashboard() {
            this.$router.push('/dashboard')
        },
        goBack() {
            this.$router.go(-1);
        },
        previousTutorialPage() {
            this.tutorialPageVisible--
        },
        nextTutorialPage() {
            this.tutorialPageVisible++
        },
        skipTutorial() {
            this.$router.back()
        }
    }
}
</script>

<style scoped>

#skipTutorial {
    height: auto;
    width: auto;
}

#directionsTitle {
    text-align: center;
    color: #6A6969;
    font-size: 140%;
    margin-bottom: 5px;
}

#articleAndButtonsDirections {
    text-align: center;
}

#articleAndButtonsDirections img {
    max-width:100%;
    max-height:100%;
}

#gameButtonsDirections {
    text-align: center;
}

#gameButtonsDirections img {
    max-width:100%;
    max-height:100%;
}

#scrollArticlesDirections {
    text-align: center;
}

#scrollArticlesDirections img {
    max-width:100%;
    max-height:100%;
}

#doneButtonDirections {
    text-align: center;
}

#doneButtonDirections img {
    max-width:100%;
    max-height:100%;
}

#penalizationDirections {
    text-align: center;
}

#penalizationDirections img {
    max-width:40%;
    max-height:40%;
}

#rewardDirections {
    text-align: center;
}

#rewardDirections img {
    max-width:40%;
    max-height:40%;
}

#backToDashboard, #skipTutorial {
    text-align: left;
}

#toDetailsPageLink {
    text-decoration: underline;
    text-decoration-color: black;
    font-weight: bold;
}

#directionsPages {
    text-align: center;
    margin-right: 1em;
    margin-left: 1em;
}

#testButton {
    text-align: center;
}

.dashboardButton {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.facebookStyleButton {
    margin: 3%;
    width: 20%;
    color: white;
    height: 30px;
    border: 2px solid;
    border-color: #6A6969;
    border-radius: 25px;
    background-color: #4267B2;
    font-size: 20px;
    font-style: bold;
}

.facebookStyleButton:hover {
    margin: 3%;
    color: white;
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-color: #6A6969;
    border-radius: 25px;
    background-color: #334f88;
    font-size: 20px;
    font-style: bold;
    cursor: pointer;
}

.twitterStyleButton {
    margin: 3%;
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-radius: 25px;
    color: black;
    background-color: #1DA1F2;
    font-size: 20px;
    font-style: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.twitterStyleButton:hover {
    margin: 3%;
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-radius: 25px;
    color: black;
    background-color: #1a88cc;
    font-size: 20px;
    font-style: bold;
    cursor: pointer;
}

.tutorialButtonsDiv {
    display: flex;
    justify-content: center;
}

.tutorialSnippets {
    text-align: center;
}

table {
    border-collapse: collapse;
}

table, th, td {
    border: 1px solid black;
}
</style>
