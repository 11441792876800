<template>
    <div>
        <div>
            <div id="backToDashboard">
                <button id="dashboardButton"
                        :class="{'facebookStyleButton': user.user_authentication=='facebook',
                                        'twitterStyleButton': user.user_authentication!='facebook'}"
                        @click="goBack()"
                        type="button" aria-label="back to dashboard">
                    <fa icon="arrow-left"/>
                </button>
            </div>
            <div id="aboutTitle">About</div>
            <div id="faqSection" class="container-fluid">
                <div class="container">
                    <dl>
                        <ul>
                            <li>
                                <a href="#faq-q1">What is Fakey?</a>
                            </li>
                            <li>
                                <a href="#faq-q2">How does Fakey source its information?</a>
                            </li>
                            <li>
                                <a href="#faq-q3">Who is behind Fakey?</a>
                            </li>
                            <li>
                                <a href="#faq-q4">How do you play Fakey?</a>
                            </li>
                            <li>
                                <a href="#faq-q5">What does the Fakey news feed represent?</a>
                            </li>
                            <li>
                                <a href="#faq-q6">How is your score calculated?</a>
                            </li>
                            
                        </ul>

                        <dt id="faq-q1">What is Fakey?</dt>
                        <dd>
                            Fakey is a game that aims to teach media literacy and study how people interact with misinformation.
                        </dd>
                        <dt id="faq-q2">How does Fakey source its information?</dt>
                        <dd>
                            Fakey uses a list of the most popular 50 English-language sources
			    from the <a href="https://iffy.news/index/" target="_blank">Iffy Index 
                            of Unreliable Sources</a> that have either a 'low' or 'very-low' factual 
                            rating from <a href="https://mediabiasfactcheck.com/" target="_blank">Media Bias Fact Check</a>. 
                            Articles from these sources, which are labeled 'questionable' in Fakey, are
		            retrieved daily using the <a href="https://serpapi.com/" target="_blank">SerpAPI</a>. 
                            In addition, Fakey retrieves the latest top headlines daily from English-language news sources 
                            via the <a href="https://newsapi.org/" target="_blank">News API</a>. These sources are labeled 
                            'mainstream' in Fakey. If any of the sources from the News API also appear in the Iffy Index, 
                            they are excluded.
                        </dd>
                        <dt id="faq-q3">Who is behind Fakey?</dt>
                        <dd>
                            Fakey is a project of 
                            <a href="https://osome.iu.edu/" target="_blank">OSoMe</a>
                            and is maintained by the <a href="https://iuni.iu.edu/" target="_blank">IUNI</a> 
                            IT team, including 
                            <a href="https://iuni.iu.edu/about/people/person/sarah_beverton"
                                target="_blank">Sarah Beverton</a> and 
                            <a href="https://twitter.com/rishab1r"
                                target="_blank">Rishab Ravi</a>.
                            It was originally developed by
                            <a href="https://www.linkedin.com/in/mihai-avram-15493182/"
                                target="_blank">Mihai Avram</a> and 
                            <a target="_blank"
                                href="http://cnets.indiana.edu/fil/">Filippo Menczer</a>
                                at Indiana University.
                        </dd>
                        <dt id="faq-q4">How do you play Fakey?</dt>
                        <dd>
                            Inspect each article in the feed just as you would in your favorite social media. Look at the image, 
                            headline, and description to decide whether it's credible, in which case, you may share or like it. 
                            Select fact-check to indicate that you don't trust the article. Select skip or hint if you are not sure.
                        </dd>
                        <dt id="faq-q5">What does the Fakey news feed represent?</dt>
                        <dd>
                            <ul>
                                <li>You will see a simulated news feed with articles. Some articles come from legitimate news 
                                sources, and some come from sites that typically publish false or misleading reports, 
                                clickbait headlines, conspiracy theories, junk science, and other types of misinformation.</li>
                                <li>The number of likes or shares for each article is generated by a power law distribution to simulate what a user
                                might see on their social media feed and does not represent the actual number of likes and shares.</li>
                            </ul>
                        </dd>
                        <dt id="faq-q6">How is your score calculated?</dt>
                        <dd>
                            <ul>
                                <li>The game will score each action you take. Your points describe how much experience you have. 
                                Your skill measures how good you are at promoting information from trustworthy sources and spotting articles 
                                from low credibility sources. You will get top points for sharing articles from credible sources and 
                                fact-checking articles from suspicious sources.</li>
                                <li>Sharing an article from an untrusted source will cause 
                                your skill to decrease.</li>
                                <li>Liking a suspicious article or fact-checking information from a legitimate source will 
                                get you a few points. You may also use a hint, but it will cost you a few points.</li>
                            </ul>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'About',
    computed: {
        ...mapState(['user'])
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    },
    mounted() {
        let faqElement = this.$router.currentRoute.value.hash.replace("#", "");
        if (faqElement) {
            this.$nextTick(() =>
            window.document.getElementById(faqElement).scrollIntoView());
        }
    }
}
</script>

<style scoped>

#faqSection {
    text-align: left;
    margin-right: 1em;
    margin-left: 1em;
}

#aboutTitle {
    text-align: center;
    color: #6A6969;
    font-size: 140%;
    margin-bottom: 5px;
}

#backToDashboard {
    text-align: left;
}

#dashboardButton {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.facebookStyleButton {
    margin: 3%;
    color: white;
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-color: #6A6969;
    border-radius: 25px;
    background-color: #4267B2;
    font-size: 20px;
    font-style: bold;
}

.facebookStyleButton:hover {
    margin: 3%;
    color: white;
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-color: #6A6969;
    border-radius: 25px;
    background-color: #334f88;
    font-size: 20px;
    font-style: bold;
    cursor: pointer;
}

.twitterStyleButton {
    margin: 3%;
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #1DA1F2;
    font-size: 20px;
    font-style: bold;
}

.twitterStyleButton:hover {
    margin: 3%;
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #1a88cc;
    font-size: 20px;
    font-style: bold;
    cursor: pointer;
}
</style>
