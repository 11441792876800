<template>
  <div>
    <div id="privacyPolicyScreen">
      <div id="backToDashboard">
        <button
          id="dashboardButton"
          v-bind:class="{
            facebookStyleButton: user.user_authentication == 'facebook',
            twitterStyleButton: user.user_authentication != 'facebook',
          }"
          @click="goBack()"
          type="button" aria-label="back to dashboard"
        >
          <fa icon="arrow-left"/>
        </button>
      </div>
      <div id="privacyTitle">Fakey Privacy Policy</div>
      
      <div id="privacyContent">
        <p>Basic privacy practices for websites at IU: 
            <a href="https://privacy.iu.edu/privacy/global.html" target="_blank">
                https://privacy.iu.edu/privacy/global.html</a>
        </p>
        <p>Effective: 2023-02-23</p>

        <h2>Applicability</h2>
        <p>
            This privacy notice applies only to the Indiana University Fakey and
            explains our practices concerning the collection, use, and disclosure of
            visitor information. Visitor information collected by Indiana University
            will be used only as outlined in this privacy notice.
        </p>
        <p>
            Other units at the university may collect and use visitor information in
            different ways. Therefore, visitors to other university websites should
            review the privacy notices for the particular sites they visit. Indiana
            University is not responsible for the content of other websites or for
            the privacy practices of websites outside the scope of this notice.
        </p>

        <h2>Changes</h2>
        <p>
            Because Internet technologies continue to evolve rapidly, Indiana
            University may make appropriate changes to this notice in the future.
            Any such changes will be consistent with our commitment to respecting
            visitor privacy, and will be clearly posted in a revised privacy notice.
        </p>

        <h2>Collection and Use</h2>

        <h3>Passive/Automatic Collection</h3>
        <p>
            When you view pages on our site, the web server automatically collects
            certain technical information from your computer and about your
            connection including:
        </p>
        <ul>
            <li>your IP address</li>
            <li>user-specific information on which pages are visited</li>
            <li>aggregate information on pages visited</li>
            <li>the date and time of visit</li>
        </ul>
        <h6>Information Collected from Social Media Platforms and Third-Party Services:
        </h6>
        <p>When you log in using Facebook, Twitter or Google, we collect
            basic information such as full name,
            profile picture url and location.
        </p>
        <p>
            Continued use of our website indicates consent to the collection, use,
            and disclosure of this information as described in this notice.
        </p>
        <p>
            This technical information is retained in detail; it may not have a
            current retention/deletion schedule. 
        </p>
        <p>
            If you would like to learn more  
            about how to delete your personal information, please see the section 
            <a href="#delete">below</a>.
        </p>
        <h3>Active/Manual/Voluntary Collection</h3>
        <p>
            Other than automatically collected technical information about your
            visit (described above, or cookies, described below), we may ask you to
            provide information voluntarily, such as through forms or other manual
            input—in order to make products and services available to you, to
            maintain and manage our relationship with you, including providing
            associated services or to better understand and serve your needs. This
            information is generally retained as long as you continue to maintain a
            relationship with us. Your providing this information is wholly
            voluntary. However, not providing the requested information (or
            subsequently asking that the data be removed) may affect our ability to
            deliver the products or service for which the information is needed.
            Providing the requested information indicates your consent to the
            collection, use, and disclosure of this information as described in this
            notice. Information we may actively collect could include:
        </p>
        <ul>
            <li>
            information volunteered by the visitor, such as preferences, survey
            information and/or site registrations
            </li>
        </ul>
        <p>
            Other information we are currently capturing includes: user/news
            interactions in the game, articles seen be each user during gameplay, as well as
            experience and skill for each user.
        </p>
        <h3>Information Usage</h3>
        <p>This information is used:</p>
        <ul>
            <li>to customize the content of our site</li>
            <li>
            to post user skill and experience on public leaderboards
            </li>
            <li>
            for research and game improvement
            </li>
        </ul>
        
        <h3>Information Sharing</h3>
        <p>
            We do not share any aggregate, non-personally identifiable information
            with other entities or organizations.
        </p>
        <p>
            We do not share any personally identifiable information with other
            entities or organizations, except when legally required to do so, at the
            request of governmental authorities conducting an investigation, to
            verify or enforce compliance with the policies governing our website and
            applicable laws, or to protect against misuse or unauthorized use of our
            website.
        </p>
        <p>
            Except as described above, we will not share any information with any
            party for any reason.
        </p>
        <p>
            Except as provided in the Disclosure of Information section below, we do
            not attempt to use the technical information discussed in this section
            to identify individual visitors.
        </p>
        <h3>Cookies</h3>
        <p>
            A <a href="https://epic.org/privacy/internet/cookies/" target="_blank">cookie</a> is a
            small data file that is written to your hard drive that contains
            information about your visit to a web page. If you prefer not to receive
            cookies, you may configure your browser not to accept them at all, or to
            notify and require approval before accepting new cookies. Some web
            pages/sites may not function properly if the cookies are turned off, or
            you may have to provide the same information each time you visit those
            pages.
        </p>
        <p>
            In order to customize the information and services offered to you, our
            site uses cookies to:
        </p>
        <ul>
            <li>
            Obviate the need for users having to sign in repeatedly on
            Facebook/Twitter/Google, and also to identify the users when playing Fakey
            </li>
        </ul>
        <h3>Children</h3>
        <p>
            This site is not directed to children under 13 years of age, does not
            sell products or services intended for purchase by children, and does
            not knowingly collect or store any personal information, even in
            aggregate, about children under the age of 13. We encourage parents and
            teachers to be involved in children’s Internet explorations. It is
            particularly important for parents to guide their children when they are
            asked to provide personal information online.
        </p>
        <h3>Use of Third Party Services</h3>
        <p>
            This website uses Google Analytics, a web analytics service provided by
            Google, Inc. ("Google"). Google Analytics uses cookies (described above)
            to help the website analyze how users use the site. The information
            generated by the cookie about your use of the website (including
            possibly your IP address) will be transmitted to and stored by Google.
        </p>
        <p>
            For more information, please visit
            <a href="http://www.google.com/policies/privacy" target="_blank"
            >Google’s Privacy Policy</a
            >.
        </p>
        <h3>Social Media</h3>
        <p>
            Third-party social media sites may collect information from this site.
            Indiana University is not responsible for their privacy practices. This
            site embeds features from third party social media sites, including:
        </p>
        <ul>
            <li>
            Facebook (visit Facebook's privacy policy at:
            <a href="https://www.facebook.com/privacy/explanation" target="_blank">
            https://www.facebook.com/privacy/explanation</a>)
            </li>
            <li>
            Twitter (visit Twitter's privacy policy at:
            <a href="https://twitter.com/en/privacy" target="_blank">
            https://twitter.com/en/privacy</a>)
            </li>
        </ul>
        <h2>Disclosure of Information</h2>

        <p>
            Other than sharing your information with other appropriate university
            personnel and units to ensure the quality, functionality, and security
            of our website, or manage your relationship with us, we will not
            disclose personally identifiable information about your use of the site
            except under the following circumstances:
        </p>
        <ul>
            <li>With your prior written (including email) consent</li>
            <li>
            When we have given you clear notice that we will disclose information
            that you voluntarily provide
            </li>
            <li>
            With appropriate external parties, such as law enforcement agencies,
            in order to investigate and respond to suspected violations of law or
            university policy. Any such disclosures shall comply with all
            applicable laws and university policies.
            </li>
        </ul>
        <div id="delete">
            <h2>Deleting Personal Information</h2>
            <p>If you'd like to access, correct, change/update, delete or restrict the 
                processing of your personal information, please submit this 
                <a href="https://osome.iu.edu/about/contact" target="_blank">contact form</a> 
                and include specifics about your request. We will comply with your request 
                within a reasonably prompt timeframe.
            </p>
        </div>
        <h2>Security</h2>
        <p>
            Due to the rapidly evolving nature of information technologies, no
            transmission of information over the Internet can be guaranteed to be
            completely secure. While Indiana University is committed to protecting
            user privacy, IU cannot guarantee the security of any information users
            transmit to university sites, and users do so at their own risk.
        </p>
        <p>
            We have appropriate security measures in place in our physical
            facilities to protect against the loss, misuse, or alteration of
            information that we have collected from you at our site.
        </p>
        <p>
            Once we receive user information, we will use reasonable safeguards
            consistent with prevailing industry standards and commensurate with the
            sensitivity of the data being stored to maintain the security of that
            information on our systems.
        </p>
        <p>
            We will comply with all applicable federal, state and local laws
            regarding the privacy and security of user information.
        </p>
        <h2>Links to non-university sites</h2>
        <p>
            Indiana University is not responsible for the availability, content, or
            privacy practices of non-university sites. Non-university sites are not
            bound by this site privacy notice policy and may or may not have their
            own privacy policies.
        </p>
        <h2>Privacy Notice Changes</h2>
        <p>
            From time to time, we may use visitor information for new, unanticipated
            uses not previously disclosed in our privacy notice.
        </p>
        <p>
            Only data collected from the time of the policy change forward will be
            used for these new purposes. If you are concerned about how your
            information is used, you should check back at our website periodically.
        </p>
        <h2>Contact Information</h2>
        <p>
            If you have questions or concerns about this policy, please contact us.
        </p>
        <blockquote>
            Indiana University<br />ATTN: OSoMe<br />
            1015 E 11th St.<br />Bloomington, IN 47401<br />
        </blockquote>
        <p>
            If you feel as though this site’s privacy practices differ from the
            information stated, you may contact us at the listed address or phone number or 
            use this 
            <a href="https://osome.iu.edu/about/contact" target="_blank">contact form</a>.
        </p>
        <p>
            If you feel that this site is not following its stated policy and
            communicating with the owner of this site does not resolve the matter,
            or if you have general questions or concerns about privacy or
            information technology policy at Indiana University, please contact the
            chief privacy officer through the University Information Policy Office,
            812-855-UIPO, <a href="mailto:privacy@iu.edu" target="_blank">privacy@iu.edu</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Privacy',
    computed: {
        ...mapState(['user'])
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    },
};
</script>

<style scoped>
#privacyTitle {
  text-align: center;
  color: #6A6969;
  font-size: 140%;
  margin-bottom: 5px;
}

#backToDashboard {
  text-align: left;
}

#privacyPolicyScreen {
  text-align: left;
}

#privacyContent {
    margin-left: 1em;
    margin-right: 1em;
}

.facebookStyleButton {
  margin: 3%;
  color: white;
  width: 20%;
  height: 30px;
  border: 2px solid;
  border-color: #6A6969;
  border-radius: 25px;
  background-color: #4267b2;
  font-size: 20px;
  font-style: bold;
}

.facebookStyleButton:hover {
  margin: 3%;
  color: white;
  width: 20%;
  height: 30px;
  border: 2px solid;
  border-color: #6A6969;
  border-radius: 25px;
  background-color: #334f88;
  font-size: 20px;
  font-style: bold;
  cursor: pointer;
}

.twitterStyleButton {
  margin: 3%;
  width: 20%;
  height: 30px;
  border: 2px solid;
  border-radius: 25px;
  background-color: #1da1f2;
  font-size: 20px;
  font-style: bold;
}

.twitterStyleButton:hover {
  margin: 3%;
  width: 20%;
  height: 30px;
  border: 2px solid;
  border-radius: 25px;
  background-color: #1a88cc;
  font-size: 20px;
  font-style: bold;
  cursor: pointer;
}

#dashboardButton {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

a {
    text-decoration: underline;
}

</style>
