<template>
    <footer class="footer">
      <router-link class="footer-link" to="/about">About</router-link>
      <router-link class="footer-link" to="/directions" @click.native="setTutorialReason">Tutorial</router-link>
      <router-link class="footer-link" to="/privacy">Privacy Policy</router-link>
      <a class="footer-link" href="https://osome.iu.edu/about/contact" target="_blank" rel="noopener noreferrer">Feedback</a>
    </footer>
  </template>

<script>
export default {
  methods: {
    setTutorialReason() {
      let reason = 'voluntary';
      if (this.$route.path !== '/dashboard') {
        reason = 'clickedFromFooter';
      }
      this.$store.commit('setTutorialReason', reason);
    },
  },
};
</script>
  
<style scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-top: 1px solid #dee2e6;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.footer-link {
  color: #0364CC;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #0056b3;
}
</style>