<template>
    <div>
        <div id="goBack"><button v-bind:class="{'facebookStyleButton': user.user_authentication=='facebook', 'twitterStyleButton': user.user_authentication!='facebook'}" v-on:click="goBack()" id="dashboardButton" type="button"><fa icon="arrow-left"/></button></div>
        <br>
        <div id="errorTitle">Oops! An error has occurred.</div>
        <div id="errorDescription">
            <br>
            Please try again shortly, or contact <b>mihai.v.avram@gmail.com</b> if the issue persists.
            <br>
            <h3><i>Below are the details of the error</i></h3>
            <b>During Action:</b> {{ error.action }}
            <br>
            <b>When:</b> {{ error.timestamp }}
            <br>
            <b>Reason:</b> {{ error.message }}
            <br>
            <b>Details:</b> {{ error.details }}
            <br>
            <br>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Errorscreen',
    computed: {
        ...mapState(['user']),
        ...mapState(['error'])
    },
    methods: {
        goBack() {
            this.$router.back()
        }
    }
}
</script>

<style scoped>
#errorDescription {
    text-align: left;
}

#errorTitle {
    text-align: center;
    color: #6A6969;
    font-size: 140%;
    margin-bottom: 5px;
}

#goBack {
    text-align: left;
}

.facebookStyleButton {
    color: white;
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-color: #6A6969;
    border-radius: 25px;
    background-color: #4267B2;
    font-size: 20px;
    font-style: bold;
}

.facebookStyleButton:hover {
    color: white;
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-color: #6A6969;
    border-radius: 25px;
    background-color: #334f88;
    font-size: 20px;
    font-style: bold;
    cursor: pointer;
}

.twitterStyleButton {
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #1DA1F2;
    font-size: 20px;
    font-style: bold;
}

.twitterStyleButton:hover {
    width: 20%;
    height: 30px;
    border: 2px solid;
    border-radius: 25px;
    background-color: #1a88cc;
    font-size: 20px;
    font-style: bold;
    cursor: pointer;
}
</style>
