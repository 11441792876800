<template>
    <div>
        <div class="alert alert-dismissible alert-primary" role="alert"
            v-if="showInstallBanner">
            <button type="button" class="close" @click="dismiss">
                <span>&times;</span>
            </button>
            Install Fakey?
            <button class="btn btn-primary mr-2 ml-3" @click="install">
                Install
            </button>
            <button class="btn btn-primary" @click="dismiss">
                No, thanks
            </button>
        </div>
        <div class="alert alert-dismissible alert-primary" role="alert"
            v-if="iOSDevice">
            <button type="button" class="close" @click="dismiss">
                <span>&times;</span>
            </button>
            To add this app to your home screen: <br/>
            Tap the "Share" button <i class="material-icons" style="font-size: 16px;">ios_share</i> and select the "Add to Home Screen" option
        </div>
    </div>
</template>

<script>
import UAParser from 'ua-parser-js';

export default {
    name: 'PWABanner',
    data() {
        return {
            showInstallBanner: null,
            iOSDevice: false
        };
    },
    created() {
        const lastDismissed = localStorage.getItem('fakeyLastDismissed');
        if (!lastDismissed || new Date(lastDismissed) < new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)) {
            window.addEventListener('beforeinstallprompt', e => {
                e.preventDefault();
                this.showInstallBanner = e;
            });
        }

        window.addEventListener('appinstalled', () => {
            this.showInstallBanner = null;
            localStorage.removeItem('fakeyLastDismissed');
        });
    },
    methods: {
        async dismiss() {
            this.showInstallBanner = null;
            this.iOSDevice = false;
            localStorage.setItem('fakeyLastDismissed', new Date().toISOString());
        },
        async install() {
            this.showInstallBanner.prompt();
            localStorage.removeItem('fakeyLastDismissed');
        }
    },
    mounted() {
        const parser = new UAParser();
        const device = parser.getDevice();
        const lastAlertTime = localStorage.getItem('lastAlertTime');
        const currentTime = Date.now();
        const isSafari = window.safari;
        // we only want to show instructions if the user is using Safari on an iOS device because Chrome on iOS doesn't have a 'save to home screen' option
        if ((device.type === 'tablet' || device.type === 'mobile') && device.vendor === 'Apple' && (isSafari !== undefined) && (!lastAlertTime || (currentTime - lastAlertTime) > (30 * 24 * 60 * 60 * 1000))) {
            this.iOSDevice = true;
        }
        localStorage.setItem('lastAlertTime', currentTime)
    }
};
</script>