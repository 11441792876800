<template>
    <div>
        <br />
        <div id="fakeNewsGameLogoDiv">
            <img
            id="fakeyLogoTransparentBackground"
            src="../statics/FakeyLogoTransparentBackground.png"
            alt="Fakey"
            />
        </div>
        <div id="appDescription">
            <h1>Welcome to Fakey!</h1>
            <p>
            This game aims to teach media literacy and study how people interact with misinformation. The goal is to support a healthy social media experience by promoting information from reliable sources and not from low credibility sources.
            </p>
            <p>
            While you are not required to login, you may want to do so to save your progress, scores and see how you compare to others. <br> Click the button below to get started!
            </p>
            <button @click="goToLogin">Play</button>
        </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  
  export default {
      name: 'HomeScreen',
      methods: {
        goToLogin() {
            this.$router.push('/login')
        }
      }
  };
  </script>
  
  <style scoped>
  button {
    margin: 1%;
  }

  #appDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
    margin-left: 1em;
    margin-right: 1em;
    text-align: left;
  }
  
  #appDescription button {
    background-color: #0364CC;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  #appDescription button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  #fakeyLogoTransparentBackground {
    border-style: none;
    /* border-color: black;
      border-width: 1px; */
    height: 200px;
    width: 200px;
  }

  #fakeNewsGameLogoDiv {
    margin: 0 auto;
    text-align: center;
  }
  
  </style>
  